import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import SearchKeywordPage from "./pages/SearchKeywordPage";
import NewsSourcesPathPage from "./pages/NewsSourcesPath";
import CategoriesPage from "./pages/CategoriesPage";
import ClientsPage from "./pages/ClientsPage";
import NewsSourcesPage from "./pages/NewsSources";
import ClientWeekDayPage from "./pages/ClientWeekDay";
import RolesPage from "./pages/RolesPage";
import ScrapeTemplatesPage from "./pages/ScrapeTemplates";
import ClientSourcesPage from "./pages/ClientSources";
import NewsPage from "./pages/NewsPage";
import ProxiesPage from "./pages/ProxiesPage";
import UserAgentsPage from "./pages/UserAgents";
import TvChannels from "./pages/TvChannels";
import VideoNews from "./pages/VideoNews";
import NotificationsPage from "./pages/Notifications";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */

          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute
          path="/client-week-day/:id"
          component={ClientWeekDayPage}
        />
        <ContentRoute path="/search-keyword" component={SearchKeywordPage} />
        <ContentRoute exact path="/news-sources" component={NewsSourcesPage} />
        <ContentRoute path="/categories" component={CategoriesPage} />
        <ContentRoute path="/proxies" component={ProxiesPage} />
        <ContentRoute path="/clients" component={ClientsPage} />
        <ContentRoute path="/notifications" component={NotificationsPage} />
        <ContentRoute path="/user-agents" component={UserAgentsPage} />
        <ContentRoute path="/tv-channels" component={TvChannels} />
        <ContentRoute path="/video-news" component={VideoNews} />

        <ContentRoute path="/news" component={NewsPage} />
        <ContentRoute path="/roles" component={RolesPage} />
        <ContentRoute
          exact
          path="/news-sources/scrape-templates/:id"
          component={ScrapeTemplatesPage}
        />
        <ContentRoute
          exact
          path="/news-sources/path/:id"
          component={NewsSourcesPathPage}
        />
        <ContentRoute
          exact
          path="/clients-sources/:id"
          component={ClientSourcesPage}
        />

        <Redirect to="error/error-v5" />
      </Switch>
    </Suspense>
  );
}
