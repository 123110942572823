import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const newsSourcesPathApi = createApi({
  reducerPath: "newsSourcesPathApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["NewsSourcesPath"],
  endpoints: (builder) => ({
    getSourcesPath: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, status, name } = filter;
    
        return {
          url: `news-source-paths?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.name ? "&Name=" + name?.name : ""
          }${name?.url ? "&Url=" + name?.url : ""}${
            name?.NewsSourceId ? "&NewsSourceId=" + name?.NewsSourceId : ""
          }${
            name?.Id ? "&Id=" + name?.Id : ""
          }${name?.FromDate ? "&FromDate=" + name?.FromDate : ""}${
            name?.ToDate ? "&ToDate=" + name?.ToDate : ""
          }${
            name?.status == true
              ? `&Status=${name?.status}`
              : name?.status == false
              ? `&Status=${name?.status}`
              : ""
          }`,
        };
      },
      providesTags: ["NewsSourcesPath"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getSourcesItemPath: builder.query({
      query(id) {
        return {
          url: `news-source-paths/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getSourcesItemPathCheck: builder.query({
      query(id) {
        return {
          url: `news-source-paths/check/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createSourcesPath: builder.mutation({
      query(form) {
        return {
          url: "news-source-paths",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["NewsSourcesPath"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateSourcesPath: builder.mutation({
      query(data) {
        return {
          url: "news-source-paths",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["NewsSourcesPath"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatusPath: builder.mutation({
      query(data) {
        return {
          url: `news-source-paths/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["NewsSourcesPath"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteSourcesPath: builder.mutation({
      query(id) {
        return {
          url: `news-source-paths/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["NewsSourcesPath"],
    }),
  }),
});

export const {
  useGetSourcesItemPathCheckQuery,
  useGetSourcesPathQuery,
  useGetSourcesItemPathQuery,
  useUpdateSourcesPathMutation,
  useCreateSourcesPathMutation,
  useChangeStatusPathMutation,
  useDeleteSourcesPathMutation,
} = newsSourcesPathApi;
