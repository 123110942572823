import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const newsSourcesApi = createApi({
  reducerPath: "newsSourcesApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["NewsSources"],
  endpoints: (builder) => ({
    getSources: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, status, name } = filter;

        let statusParam = "";
        if (
          name?.status !== null &&
          name?.status !== "null" &&
          name?.status !== undefined
        ) {
          statusParam =
            name?.status == "true" || true
              ? `&Status=${name?.status}`
              : name?.status == "false" || false
              ? `&Status=${name?.status}`
              : "";
        }
        return {
          url: `news-sources?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name?.name ? "&Name=" + name?.name : ""
          }${name?.url ? "&Url=" + name?.url : ""}${
            name?.FromDate ? "&FromDate=" + name?.FromDate : ""
          }${name?.ToDate ? "&ToDate=" + name?.ToDate : ""}${statusParam}${
            name?.Id ? "&Id=" + name?.Id : ""
          }`,
        };
      },

      providesTags: ["NewsSources"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getNotifications: builder.query({
      query(filter) {
        const { skip, take } = filter;
        return {
          url: `scrape-notifications?Skip=${skip}&Take=${take}`,
        };
      },
      providesTags: ["Notifications"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getSourcesItem: builder.query({
      query(id) {
        return {
          url: `news-sources/${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createSources: builder.mutation({
      query(form) {
        return {
          url: "news-sources",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["NewsSources"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateSources: builder.mutation({
      query(data) {
        return {
          url: "news-sources",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["NewsSources"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    changeStatus: builder.mutation({
      query(data) {
        return {
          url: `news-sources/${data?.id}/status`,
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["NewsSources"],
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteSources: builder.mutation({
      query(id) {
        return {
          url: `news-sources/${id}`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["NewsSources"],
    }),
    deleteNotification: builder.mutation({
      query(id) {
        return {
          url: `scrape-notifications/${id}`,
          method: "DELETE",
        };
      },
      // invalidatesTags: ["Notifications"],
    }),

    deleteNotificationAll: builder.mutation({
      query(id) {
        return {
          url: `scrape-notifications/all`,
          method: "DELETE",
          data: id ? { id } : { id: [] },
        };
      },
  
    }),
  }),
});

export const {
  useGetSourcesQuery,
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useGetSourcesItemQuery,
  useUpdateSourcesMutation,
  useCreateSourcesMutation,
  useDeleteNotificationMutation,
  useDeleteNotificationAllMutation,
  useChangeStatusMutation,
  useDeleteSourcesMutation,
} = newsSourcesApi;
