import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import Badge from "@material-ui/core/Badge";
import { IoMdNotificationsOutline } from "react-icons/io";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useDeleteNotificationMutation,
  useDeleteNotificationAllMutation,
  useLazyGetNotificationsQuery,
} from "redux/api/NewsSources/newsSourcesApi";
import * as signalR from "@microsoft/signalr";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { setErrorCount } from "redux/features/NewsSources/newsSourcesSlices";
import { Button, Card } from "react-bootstrap";

const NotificationsPanel = () => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isBadgeAnimating, setIsBadgeAnimating] = useState(false); 
  const errorCount = useSelector((state) => state.sources.errorCount);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://apiadmin.memo.com.az/scrape-error-hub")
      .withAutomaticReconnect()
      .build();
    connection
      .start()
      .then(() => {
        connection.on("NotifyErrors", (error) => {
          dispatch(setErrorCount(error?.count));
        });
      })
      .catch((error) => console.error("SignalR bağlantısı hatası:", error));

    return () => {
      connection
        .stop()
        .then(() => console.log("SignalR bağlantısı kapatıldı."));
    };
  }, []);

  const [getNotifications, { data, isLoading }] = useLazyGetNotificationsQuery();
  const [deleteNotification,{isLoading:deleteLoading,isSuccess}] = useDeleteNotificationMutation();
  const [deleteNotificationAll,{isLoading:deleteAllLoading,isSuccess:deleteAllSuccess}] = useDeleteNotificationAllMutation();

  const handleDeleteAll = () => {
    deleteNotificationAll({});
  };

  const handleToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      getNotifications({ skip: 0, take: 10 });
    }
  }, [isDropdownOpen, errorCount]);

  useEffect(() => {
    if (deleteAllSuccess) {
      dispatch(setErrorCount(0));
    }
  }, [deleteAllLoading]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(setErrorCount(errorCount-1));
    }
  }, [deleteLoading]);
  useEffect(() => {
    if (errorCount > 0) {
      setIsBadgeAnimating(true);
      const timer = setTimeout(() => setIsBadgeAnimating(false), 300); 
      return () => clearTimeout(timer); 
    }
  }, [errorCount]);

  return (
    <Dropdown drop="down" alignRight onToggle={handleToggle}>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-notifications"
      >
        <div>
          <span className="symbol symbol-35 symbol-light-primary ml-3">
            <span className="symbol-label font-size-h5 font-weight-bold notification_icon">
              {errorCount > 0  ? (
                <Badge
                  max={999}
                  badgeContent={errorCount}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={`badge-animate ${isBadgeAnimating ? "scale" : ""}`}
                >
                  <IoMdNotificationsOutline />
                </Badge>
              ) : (
                <IoMdNotificationsOutline />
              )}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {/* Header */}
        <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
          <h6 className="m-0">Bildirişlər</h6>
          {data?.data?.length > 0 && (
            <Button variant="link" size="sm" onClick={handleDeleteAll}>
              Hamısını sil
            </Button>
          )}
        </div>

        <div className="p-3 notification_body_scroll">
          {isLoading ? (
            <div className="text-center">
              <CircularProgress size={24} />
            </div>
          ) : data?.data?.length > 0 ? (
            data?.data?.map((notification) => (
              <Card
                key={notification.id}
                className="mb-3 d-flex flex-row align-items-center justify-content-between"
              >
                <div className="card-body p-2">
                  <h6 className="m-0">{notification?.description}</h6>
                  <p className="m-0 text-muted">
                    {notification?.created
                      ? format(
                          new Date(notification?.created),
                          "dd-MM-yyyy  HH:mm"
                        )
                      : ""}
                  </p>
                </div>
                <Button
                  variant="danger"
                  size="sm"
                  className="mr-2"
                  onClick={() => deleteNotification(notification.id)}
                >
                  Sil
                </Button>
              </Card>
            ))
          ) : (
            <p className="text-center">Bildiriş yoxdur</p>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center p-5 border-bottom">
          {data?.data?.length > 0 && (
            <Link to="/notifications">
              <Button variant="link see_all" size="sm">
                Hamısına bax
              </Button>
            </Link>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsPanel;
