import React, { useEffect, useState } from "react";
import DataTables from "./components/DashboardTables";
import Filter from "./modals/Filter";
import View from "./modals/View";
import Edit from "./modals/Edit";
import Delete from "./modals/Remove";
import DeleteAll from "./modals/RemoveAll";
import { useHistory, useLocation } from "react-router-dom";
import Create from "./modals/Create";
import ChangePassword from "./modals/ChangePassword";
import UpdatePassword from "./modals/UpdatePassword";
import Switch from "@material-ui/core/Switch/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import { updateObject } from "Helpers/updateObject";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { useGetUsersQuery } from "redux/api/Users/usersApi";
import { ToastContainer } from "react-toastify";
import { useUpdateStatusMutation } from "redux/api/Users/usersApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import RenderIf from "app/modules/UI/RenderIf";

export function Dashboard() {
  const history = useHistory(); 
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRemoveAll, setShowRemoveAll] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  function handleChange() {
    setShowFilter((key) => !key);
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);
  const location = useLocation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10); 
  const [sortField, setSortField] = useState("lastUpdatedDate");
  const [orderBy, setOrderBy] = useState(false);
  const { roles } = useSelector((state) => state.rolesSlicer);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [userId, setUserId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state.user);
  const permissions = user?.permissions;
  const [filterInputs, setFilterInputs] = useState({
    SearchTerm: "",
    firstName: "",
    lastName: "",
    address: "",
    email: "",
    deleted: false,
    FromDate: "",
    ToDate: "",
  });

  const { data, refetch, isLoading } = useGetUsersQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    order: false,
    name: filterInputs,
  });

  const [updateStatus, { isSuccess }] = useUpdateStatusMutation();

  const onUpdateUserStatus = (id, statusType) => {
    let status = !statusType;

    updateStatus({
      id,
      status,
    });
  };

  function handleChangePage(event, newPage) {
    const newUrl = `?page=${newPage}&rowsPerPage=${rowsPerPage}`;
    history.replace(newUrl);
    setPage(newPage);
  }

  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pageFromUrl = parseInt(params.get("page"), 10) || 0;
    const rowsPerPageFromUrl = parseInt(params.get("rowsPerPage"), 10) || 10;
    setPage(pageFromUrl);
    setRowsPerPage(rowsPerPageFromUrl);
  }, []);

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    history.push(`?page=0&rowsPerPage=${newRowsPerPage}`); 
  }
  const filterInputsUpdate = (e) => {
      setPage(0);
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
    setFilter(true);
  };

  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
    setSkip(0);
    setFilter(true);
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="row bg-white d-flex justify-content-center rounded h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="mb-0">İstifadəçilər</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Filtri gizlət" : "Filtri göstər"}
          className="ml-auto mb-0"
        />
      </div>

      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data?.data && !isLoading}>
        <DataTables
          setSortField={setSortField}
          setOrderByTable={setOrderBy}
          sortField={sortField}
          orderBy={orderBy}
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenRemoveAll={setShowRemoveAll}
          setOpenCreate={setShowCreate}
          setOpenChangePassword={setShowChangePassword}
          setOpenUpdatePassword={setShowUpdatePassword}
          rows={data?.data}
          rowsPerPage={rowsPerPage}
          permissions={permissions}
          page={page}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          count={data?.count}
          onUpdateUserStatus={onUpdateUserStatus}
          setUserId={setUserId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          roles={roles}
        />
      </RenderIf>

      <RenderIf condition={showRemoveAll}>
        <DeleteAll
          deleteUsersIds={deleteUsersIds}
          setDeleteUsersIds={setDeleteUsersIds}
          show={showRemoveAll}
          onHide={setShowRemoveAll}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          refetch={refetch}
        />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          onUsers={data}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          roles={roles}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          id={userId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
          roles={roles}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showChangePassword}>
        <ChangePassword
          show={showChangePassword}
          onHide={setShowChangePassword}
          handleClick={handleClick}
          id={userId}
        />
      </RenderIf>
      <RenderIf condition={showUpdatePassword}>
        <UpdatePassword
          show={showUpdatePassword}
          onHide={setShowUpdatePassword}
          handleClick={handleClick}
          id={userId}
        />
      </RenderIf>
      <RenderIf condition={showFilter}>
        <Filter
        setPage={setPage}
          show={showFilter}
          onHide={handleChange}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={userId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showView}>
        <View id={userId} open={showView} onClose={toggleDrawer(false)} />
      </RenderIf>

      {open && (
        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Şifrə uğurla dəyişdirildi!
          </Alert>
        </Snackbar>
      )}

      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
